import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { RectangleStackIcon } from "@heroicons/react/24/outline";
import HTMLReactParser from "html-react-parser";
import { Link, useLocation, useParams } from "react-router-dom";
import BtnPrevious from "../../Paging/BtnPrev";
import BtnNext from "../../Paging/BtnNext";

export default function ArticleByCategory() {
    const location = useLocation();
    const name_category = location.state.nm_category;
    document.title = `Articles by ${name_category}`;
    const uri_param = useParams();
    const slug = uri_param.slug;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [articles, setArticles] = useState([]);
    const [totalPages, setTotalPages] = useState(0)
    const [page, setPage] = useState(1)
    const [btnPrevStatus, setBtnPrevStatus] = useState('inactive')
    const [btnNextStatus, setBtnNextStatus] = useState('inactive')

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}category-article`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status) {
                        setCategories(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setError(error);
                }
            )
    }, [])

    useEffect(() => {
        (page <= 1) ? setBtnPrevStatus('inactive') : setBtnPrevStatus('active')
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}article-by-category/${slug}?page=${page}`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    setIsLoaded(true);
                    if (result.status) {
                        setArticles(result);
                        if (result.total) {
                            setTotalPages(Math.ceil(result.total / 5));
                            (page >= totalPages) ? setBtnNextStatus('inactive') : setBtnNextStatus('active')
                        }
                        else {
                            setBtnPrevStatus('inactive')
                            setBtnNextStatus('inactive')
                        }
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [slug, page, totalPages])


    let article = '';
    if (error) {
        article = <div className="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">{error.message} @ArticleByCategory</div>
    } else if (!isLoaded) {
        article =
            <div className="group animate-pulse grid gap-9">
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
            </div>
    } else {
        article =
            (articles.data.length > 0) ?
                articles.data.map((list, i) => (
                    <article key={i} className="group relative flex gap-6 items-start rounded-2xl border border-zinc-100 p-6 hover:bg-slate-50">
                        <img src={list.img_cover} alt={list.name} className="object-cover object-center rounded-xl w-36 h-36" />
                        <div>
                            <h2 className="text-base font-semibold tracking-tight text-zinc-800">
                                {/* <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 sm:-inset-x-6 sm:rounded-2xl"></div> */}
                                <a href={`/article/detail/${list.slug}`}>
                                    <span className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"></span>
                                    <span className="relative z-10">{list.title}</span>
                                </a>
                            </h2>
                            <time className="relative z-10 order-first my-3 flex items-center text-sm text-zinc-400 pl-3.5" >
                                <span className="absolute inset-y-0 left-0 flex items-center" aria-hidden="true">
                                    <span className="h-4 w-0.5 rounded-full bg-zinc-200"></span>
                                </span>
                                {list.date_post} - {list.author}
                            </time>
                            <div className="relative z-10 mt-2 text-sm text-zinc-600">{HTMLReactParser(list.content_sub)}</div>
                            <div aria-hidden="true" className="relative z-10 mt-4 flex items-center text-sm font-medium text-blue-500">
                                Read article
                                <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="ml-1 h-4 w-4 stroke-current">
                                    <path d="M6.75 5.75 9.25 8l-2.5 2.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                    </article>
                ))
                : <><div className=" bg-red-50 p-6 rounded-lg text-center w-full">{articles.message}</div></>
    }

    return (
        <Layout>
            <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="grid grid-cols-1 gap-y-20 gap-x-10 md:grid-cols-3">
                    <div className="flex flex-col gap-9 md:col-span-2">
                        {article}
                        <div className="flex justify-between">
                            <BtnPrevious status={btnPrevStatus} fn={() => setPage(page - 1)} />
                            <BtnNext status={btnNextStatus} fn={() => setPage(page + 1)} />
                        </div>
                    </div>
                    <div className="space-y-10">
                        <div className="rounded-2xl border border-zinc-100 p-6">
                            <h2 className="flex items-center text-sm font-semibold text-zinc-900 mb-8">
                                <RectangleStackIcon className="h-6 w-6" aria-hidden="true" />
                                <span className="ml-3">Category</span>
                            </h2>
                            <div className="divide-y divide-gray-100">
                                {categories.map((list, i) => (
                                    <Link key={i} to={`/article/category/${list.slug}`} state={{ nm_category: list.name }} className={`flex cursor-pointer justify-between gap-x-6 py-2 hover:text-blue-700 ${(list.slug === slug) ? "text-blue-700" : "text-gray-900"}`}>
                                        <div className="flex min-w-0 gap-x-2">
                                            <div className="min-w-0 flex-auto">
                                                <p className="text-sm leading-6 ">{list.name}</p>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                                <a href="/article" className="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 cursor-pointer bg-zinc-50 font-medium text-zinc-900 hover:bg-zinc-100 group w-full">All article</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}