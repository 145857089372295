
import { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import ArticleList from './ArticleList';
import DocumentList from './DocumentList';

export default function Home() {
    document.title = 'Home';
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}profile`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    setIsLoaded(true);
                    if (result.status) {
                        setProfile(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let name, vision, logo, socialMedia = '';
    if (error) {
        return (
            <Layout>
                <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">{error.message} @Profile</div>
                </div>
            </Layout>
        )
    } else {
        if (!isLoaded) {
            name = <div className="group animate-pulse">
                <div className="bg-slate-300 rounded-xl h-32 w-full">&nbsp;</div>
            </div>
            vision = <div className="group animate-pulse">
                <div className="bg-slate-300 rounded-xl h-20 w-full">&nbsp;</div>
            </div>
            logo = <div className="group animate-pulse">
                <div className="bg-slate-300 rounded-xl h-48 w-48">&nbsp;</div>
            </div>
            socialMedia = <div className="group animate-pulse flex space-x-28">
                <div className="bg-slate-300 rounded-xl h-12 w-32">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-12 w-32">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-12 w-32">&nbsp;</div>
            </div>
        } else {
            name = profile.name
            vision = profile.vision
            logo = <img src={profile.logo} alt="Logo" className="aspect-square object-cover w-48 md:w-40 lg:w-48" />
            socialMedia = <div className="divide-x divide-gray-100 lg:space-x-28 md:flex md:space-x-10">
                <div className="flex justify-between gap-x-6 py-2">
                    <div className="flex items-center min-w-0 gap-x-4">
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="fill-gray-500 w-8 h-8">
                            <title>whatsapp-svg</title>
                            <path id="Layer" fillRule="evenodd" className="s0" d="m448 254.1c0 122.4-101.8 222-224.1 222h-0.1c-37.2 0-73.7-9.3-106.1-27l-117.7 30.9 31.5-115c-19.4-33.7-29.6-71.9-29.6-111 0-122.4 99.6-222 222-222 59.3 0 115.1 23.1 157 65.1 41.9 42 67.1 97.7 67.1 157zm-37.5 0c0.1-49.3-21.3-95.6-56.1-130.5-34.8-34.9-81.1-54.1-130.4-54.1-101.8 0-184.6 82.8-184.6 184.5 0 34.9 9.7 68.8 28.2 98.2l4.4 7-18.6 68.1 69.8-18.3 6.7 4c28.3 16.8 60.8 25.7 94 25.7 101.7 0 186.6-82.8 186.6-184.6zm-74.9 53c1.4 2.3 1.4 13.4-3.2 26.4-4.6 13-26.7 24.8-37.4 26.4-17.6 2.6-31.4 1.3-66.6-13.9-55.7-24.1-92-80.1-94.8-83.8-2.7-3.7-22.6-30.1-22.6-57.4 0-27.3 14.3-40.7 19.4-46.3 5.1-5.5 11.1-6.9 14.8-6.9 3.7 0 7.4 0 10.6 0.2 3.4 0.2 8-1.3 12.5 9.5 4.6 11.1 15.7 38.4 17.1 41.2 1.4 2.8 2.3 6 0.5 9.7-10.6 21.2-22 20.5-16.3 30.3 21.5 36.9 42.9 49.7 75.5 66 5.5 2.8 8.8 2.3 12-1.4 3.3-3.8 13.9-16.2 17.6-21.8 3.7-5.6 7.4-4.7 12.5-2.8 5.1 1.8 32.4 15.2 37.9 18 5.5 2.7 9.2 4.1 10.5 6.6z" />
                        </svg>
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">WhatsApp</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{profile.phone}</p>
                        </div>
                    </div>
                </div>
                <li className="flex justify-between gap-x-6 py-2">
                    <div className="flex items-center min-w-0 gap-x-4">
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="fill-gray-500 w-8 h-8">
                            <title>instagram-svg</title>
                            <path id="Layer" fillRule="evenodd" className="s0" d="m446.6 348.3c-1.8 35.9-10 67.7-36.2 93.9-26.2 26.3-58 34.5-93.9 36.2-37 2.1-147.9 2.1-184.9 0-35.9-1.8-67.6-10-93.9-36.2-26.3-26.2-34.5-58-36.2-93.9-2.1-37-2.1-147.9 0-184.9 1.8-35.9 9.9-67.7 36.2-93.9 26.3-26.2 58.1-34.4 93.9-36.1 37-2.1 147.9-2.1 184.9 0 35.9 1.8 67.7 10 93.9 36.2 26.3 26.2 34.5 58 36.2 93.9 2.1 37 2.1 147.8 0 184.8zm-38.8-92.4c0-32.6 2.7-102.6-9-132.1-7.9-19.7-23-34.8-42.6-42.6-29.4-11.6-99.5-9-132.1-9-32.6 0-102.6-2.7-132.1 9-19.7 7.9-34.8 23-42.6 42.6-11.6 29.4-9 99.5-9 132.1 0 32.6-2.7 102.6 9 132.1 7.9 19.7 23 34.8 42.6 42.6 29.4 11.6 99.5 9 132.1 9 32.6 0 102.6 2.7 132.1-9 19.7-7.9 34.8-23 42.6-42.6 11.7-29.4 9-99.5 9-132.1zm-37.3-119.6c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8 0-14.8 12-26.8 26.8-26.8 14.8 0 26.8 12 26.8 26.8zm-31.5 119.6c0 63.6-51.3 114.9-114.9 114.9-63.6 0-114.9-51.3-114.9-114.9 0-63.6 51.3-114.9 114.9-114.9 63.6 0 114.9 51.3 114.9 114.9zm-40.2 0c0-41.2-33.5-74.7-74.7-74.7-41.2 0-74.7 33.5-74.7 74.7 0 41.2 33.6 74.7 74.7 74.7 41.1 0 74.7-33.5 74.7-74.7z" />
                        </svg>
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">Instagram</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{profile.instagram}</p>
                        </div>
                    </div>
                </li>
                <li className="flex justify-between gap-x-6 py-2">
                    <div className="flex items-center min-w-0 gap-x-4">
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="fill-gray-500 w-8 h-8">
                            <title>facebook-svg</title>
                            <path id="Layer" className="s0" d="m294.8 501l-0.1-173.3h57.8l11-71.7h-68.8v-46.5c0-19.7 9.7-38.8 40.5-38.8h31.2v-61c0 0-28.3-4.8-55.5-4.8-56.7 0-93.7 34.3-93.7 96.5v54.6h-63v71.7h63v173.3c-118.5-18.6-209.2-121.2-209.2-245 0-137 111-248 248-248 137 0 248 111 248 248 0 123.8-90.7 226.4-209.3 245z" />
                        </svg>
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">Facebook</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{profile.facebook}</p>
                        </div>
                    </div>
                </li>
            </div>
        }
    }

    return (
        <Layout>
            <section id="free-chapters" className="scroll-mt-14 bg-slate-100 sm:scroll-mt-32">
                <div className="overflow-hidden lg:relative">
                    <div className="mx-auto max-w-7xl p-6 md:px-8 relative items-end gap-y-12 py-10 lg:static lg:py-28 xl:py-32">
                        <div className='md:mx-24 text-center'>
                            <h2 className="font-display text-3xl font-extrabold tracking-tight text-slate-700 md:text-6xl w-auto">{name}</h2>
                            <div className="mt-4 text-lg text-blue-700">{vision}</div>
                        </div>
                        <div className='text-blue-700/10'>
                            <svg aria-hidden="true" className="absolute inset-0 h-full w-full">
                                <defs>
                                    <pattern id=":S3:" width="128" height="128" patternUnits="userSpaceOnUse" x="50%" patternTransform="translate(0 80)">
                                        <path d="M0 128V.5H128" fill="none" stroke="currentColor"></path>
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" fill="url(#:S3:)"></rect>
                            </svg>
                        </div>
                    </div>
                </div>
            </section>
            <div className="mx-auto max-w-7xl p-6 md:px-8 md:py-10">
                <div className="grid grid-cols-1 gap-y-20 gap-x-10 md:grid-cols-3">
                    <ArticleList limit="5" />
                    <DocumentList limit="5" />
                </div>
            </div>
            <div className="mx-auto max-w-7xl p-6 md:px-8">
                <div className="relative overflow-hidden bg-blue-50 p-8 md:mx-0 rounded-2xl md:px-16 md:py-20 xl:px-24">
                    <div className="md:flex justify-between items-center grid gap-9">
                        {logo}
                        {socialMedia}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
