import { NewspaperIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";

export default function ArticleList({ ...props }) {
    const limit = props.limit;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}article`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const first10 = result.data.slice(0, limit);
                        setDatas(first10);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [limit])


    let recent = '';
    if (error) {
        recent = <div className="bg-slate-300 rounded-xl h-20 w-full text-gray-500 flex items-center justify-center">{error.message} @ListArticle</div>
    } else if (!isLoaded) {
        recent = <div className="group animate-pulse w-full grid gap-4">
            <div className="bg-slate-300 h-20">&nbsp;</div>
            <div className="bg-slate-300 h-20">&nbsp;</div>
            <div className="bg-slate-300 h-20">&nbsp;</div>
            <div className="bg-slate-300 h-20">&nbsp;</div>
            <div className="bg-slate-300 h-20">&nbsp;</div>
        </div>
    } else {
        recent =
            datas.map((list, i) => (
                <div key={i} className="group py-3 hover:text-blue-900 px-6 hover:bg-slate-100 flex items-center gap-3">
                    <img src={list.img_cover} className="w-16 h-16 rounded-lg aspect-square object-cover group-hover:opacity-75" alt="" />
                    <a href={'/article/detail/' + list.slug}>
                        {list.title_sub}
                        {/* <small className="text-gray-500">{list.date_post}</small> */}
                    </a>
                </div>
            ))
    }

    return (
        <div className="rounded-2xl border border-zinc-100">
            <h2 className="flex items-center text-sm font-semibold text-zinc-900 p-6">
                <NewspaperIcon className="h-6 w-6" aria-hidden="true" />
                <span className="ml-3 text-xl">Articles</span>
            </h2>
            <div role="list" className="py-3">
                {recent}
            </div>
        </div>
    )
}