import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import BtnPrevious from "../../Paging/BtnPrev";
import BtnNext from "../../Paging/BtnNext";
import CategoryGallery from "./CategoryGallery";
import { Link } from "react-router-dom";


export default function Gallery() {
    document.title = 'Galleries';
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [galleries, setGalleries] = useState([]);
    const [totalPages, setTotalPages] = useState(0)
    const [page, setPage] = useState(1)
    const [btnPrevStatus, setBtnPrevStatus] = useState('inactive')
    const [btnNextStatus, setBtnNextStatus] = useState('inactive')

    useEffect(() => {
        (page <= 1) ? setBtnPrevStatus('inactive') : setBtnPrevStatus('active')
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}gallery?page=${page}`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    setIsLoaded(true);
                    if (result.status) {
                        setGalleries(result);
                        if (result.total) {
                            setTotalPages(Math.ceil(result.total / 12));
                            (page >= totalPages) ? setBtnNextStatus('inactive') : setBtnNextStatus('active')
                        }
                        else {
                            setBtnPrevStatus('inactive')
                            setBtnNextStatus('inactive')
                        }
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [page, totalPages])

    let content = '';
    if (error) {
        content = <div className="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">{error.message} @Gallery</div>
    } else if (!isLoaded) {
        content = <div className="group animate-pulse grid grid-cols-1 gap-6 md:gap-y-10 md:gap-x-10 md:grid-cols-3 lg:grid-cols-4">
            <div className="grid auto-rows-min grid-cols-1 items-center gap-6 md:gap-3 sm:grid-cols-2 md:grid-cols-1">
                <div className="bg-slate-300 rounded-xl h-40">&nbsp;</div>
                <div className="grid gap-3">
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                </div>
            </div>
            <div className="grid auto-rows-min grid-cols-1 items-center gap-6 md:gap-3 sm:grid-cols-2 md:grid-cols-1">
                <div className="bg-slate-300 rounded-xl h-40">&nbsp;</div>
                <div className="grid gap-3">
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                </div>
            </div>
            <div className="grid auto-rows-min grid-cols-1 items-center gap-6 md:gap-3 sm:grid-cols-2 md:grid-cols-1">
                <div className="bg-slate-300 rounded-xl h-40">&nbsp;</div>
                <div className="grid gap-3">
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                </div>
            </div>
            <div className="grid auto-rows-min grid-cols-1 items-center gap-6 md:gap-3 sm:grid-cols-2 md:grid-cols-1">
                <div className="bg-slate-300 rounded-xl h-40">&nbsp;</div>
                <div className="grid gap-3">
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                    <div className="bg-slate-300 rounded-xl h-5">&nbsp;</div>
                </div>
            </div>
        </div>
    } else {
        content =
            (galleries.data.length > 0) ?
                <>
                    {
                        <div className="text-center grid grid-cols-1 gap-6 md:gap-y-10 md:gap-x-10 md:grid-cols-3 lg:grid-cols-4 sm:text-start md:text-center">
                            {
                                galleries.data.map((list, i) => (
                                    <Link to={list.filename} target={`_img${i}`} rel="noopener noreferrer" key={i} className="group grid auto-rows-min grid-cols-1 items-center gap-6 md:gap-3 sm:grid-cols-2 md:grid-cols-1">
                                        <div className="relative h-48 overflow-hidden rounded-2xl shadow-lg sm:h-60 lg:h-40">
                                            <div className="absolute inset-0 flex items-center justify-center bg-[radial-gradient(#2C313D_35%,#000)]">
                                                <img alt={list.name} loading="lazy" className="h-full w-full object-contain object-center lg:h-full lg:w-full" decoding="async" data-nimg="1" src={list.filename} />
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="text-base font-medium tracking-tight text-slate-900 group-hover:text-blue-700 mb-3">{list.name_sub}</h3>
                                            <p className="text-sm text-zinc-400">{list.date_post}</p>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    }
                    <div className="flex justify-center mt-20 gap-3">
                        <BtnPrevious status={btnPrevStatus} fn={() => setPage(page - 1)} />
                        <BtnNext status={btnNextStatus} fn={() => setPage(page + 1)} />
                    </div>
                </>
                : <><div className=" bg-red-50 p-6 rounded-lg text-center w-full">{galleries.message}</div></>
    }


    return (
        <Layout>
            <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="md:flex justify-between mb-8">
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-4 md:mb-0">Galleries</h2>
                    <div className="flex items-center">
                        <CategoryGallery />
                    </div>
                </div>

                {content}

            </div>
        </Layout >
    )
}
