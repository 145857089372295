export default function Footer() {
    const currYear = new Date().getFullYear();
    return (
        <footer className="mt-32 flex-none">
            <div className="mx-auto w-full max-w-7xl">
                <div className="border-t border-zinc-100 pb-16 pt-10">
                    <div className="relative px-4 sm:px-8">
                        <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
                            <p className="text-sm text-zinc-400">© {currYear} - LPPM Mucis</p>
                            <p className="text-sm text-zinc-400">Dikembangkan oleh TIM IT MUCIS</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}