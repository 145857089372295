import HTMLReactParser from "html-react-parser";
import { useState, useEffect } from "react";

export default function ArticleList({ ...props }) {
    const limit = props.limit;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}article`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const filtered = result.data.slice(0, limit);
                        setArticles(filtered);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [limit])

    let article = '';
    if (error) {
        article = <div className="bg-slate-300 rounded-xl h-36 w-full text-gray-500 flex items-center justify-center">{error.message} @Article</div>
    } else if (!isLoaded) {
        article =
            <div className="group animate-pulse grid gap-9">
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-52">&nbsp;</div>
            </div>
    } else {
        article =
            (articles.length > 0) ?
                <>
                    {
                        articles.map((list, i) => (
                            <article key={i} className="group relative flex gap-6 items-start rounded-2xl border border-zinc-100 p-6 hover:bg-slate-50">
                                <div className=" w-48 h-32 overflow-hidden rounded-xl bg-gray-200 aspect-none group-hover:opacity-75 hidden sm:inline-block">
                                    <img src={list.img_cover} alt={list.name} className="object-center object-cover rounded-xl w-48 h-32" />
                                </div>
                                <div>
                                    <h2 className="text-base font-semibold tracking-tight text-zinc-800">
                                        {/* <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 sm:-inset-x-6 sm:rounded-2xl"></div> */}
                                        <a href={`/article/detail/${list.slug}`}>
                                            <span className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"></span>
                                            <span className="relative z-10">{list.title_sub}</span>
                                        </a>
                                    </h2>
                                    <time className="relative z-10 order-first my-3 flex items-center text-sm text-zinc-400 pl-3.5" >
                                        <span className="absolute inset-y-0 left-0 flex items-center" aria-hidden="true">
                                            <span className="h-4 w-0.5 rounded-full bg-zinc-200"></span>
                                        </span>
                                        {list.date_post} - {list.author}
                                    </time>
                                    <div className="relative z-10 mt-2 text-sm text-zinc-600">{HTMLReactParser(list.content_sub)}</div>
                                    <div aria-hidden="true" className="relative z-10 mt-4 flex items-center text-sm font-medium text-blue-500">
                                        Read article
                                        <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="ml-1 h-4 w-4 stroke-current">
                                            <path d="M6.75 5.75 9.25 8l-2.5 2.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                </div>
                            </article>
                        ))
                    }
                    <a href="article" className="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 cursor-pointer bg-zinc-50 font-medium text-zinc-900 hover:bg-zinc-100 group w-full">All articles</a>
                </>
                : <><div className=" bg-red-50 p-6 rounded-lg text-center w-full">{articles.message}</div></>
    }

    return (
        <div className="space-y-6 md:col-span-2">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 mb-4 md:mb-0">Recent articles</h2>
            {article}
        </div>
    )
}