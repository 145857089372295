import { useEffect, useState } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link, useLocation } from 'react-router-dom';
import Logo from './logo.png'

const navigation = [
    // { name: 'Home', href: '/' },
    { name: 'Article', href: '/article', },
    { name: 'Document', href: '/document', },
    { name: 'Gallery', href: '/gallery', },
    { name: 'About', href: '/about', },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}profile`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    setIsLoaded(true);
                    if (result.status) {
                        setProfile(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let name, logo = '';

    if (error) {
        name = <span className="sr-only">Your company</span>
        logo = <img className="h-10 w-10" src={Logo} alt="" />
    } else {
        if (!isLoaded) {
            name = <span className="sr-only"></span>
            logo = <div className="group animate-pulse">
                <div className="bg-slate-300 rounded-full h-10 w-10">&nbsp;</div>
            </div>
        } else {
            name = <span className="sr-only">{profile.name}</span>
            logo = <img className="h-10 w-10" src={profile.logo} alt="" />
        }
    }

    return (
        <div className='bg-blue-800'>
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 md:px-8" aria-label="Global">
                <div className="flex md:flex-1">
                    <Link className="-m-1.5" to={'/'}>
                        {name}
                        {logo}
                    </Link>
                </div>
                <div className="flex md:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-50"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden md:flex md:gap-x-12">
                    {navigation.map((item, i) => (
                        <Link to={item.href} key={i} className={classNames((`/${splitLocation[1]}` === item.href) ? ' text-orange-200' : 'hover:text-orange-200 text-slate-50', 'text-sm font-semibold leading-6')}>{item.name}</Link>
                    ))}
                    <Link to='https://ojs.stikesmucis.ac.id/' target='_ojs' rel='noopener noreferrer' className='hover:text-orange-200 text-slate-50 text-sm font-semibold leading-6'>Journal</Link>
                </Popover.Group>
                <div className="hidden md:flex md:flex-1 md:justify-end">
                    <a href={process.env.REACT_APP_URL_ADMIN} target='_admin' rel='noopener noreferrer' className="text-sm font-semibold leading-6 text-slate-50 hover:text-orange-200">
                        Log in <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to={'/'} className="-m-1.5 p-1.5">
                            {name}
                            {logo}
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item, i) => (
                                    <Link to={item.href} key={i} className={classNames((`/${splitLocation[1]}` === item.href) ? ' text-gray-900 bg-gray-50' : 'text-gray-900 hover:bg-gray-50', '-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7')}>{item.name}</Link>
                                ))}
                                <Link to='https://ojs.stikesmucis.ac.id/' target='_ojs' rel='noopener noreferrer' className='hover:bg-gray-50 text-gray-900 -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7'>Journal</Link>
                            </div>
                            <div className="py-6">
                                <a href={process.env.REACT_APP_URL_ADMIN} target='_admin' rel='noopener noreferrer'
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Log in
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </div>
    )
}